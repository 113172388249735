<template>
  <v-checkbox
    class="error--text"
    :label="label"
    :input-value="value"
    :value="value"
    :disabled="disabled"
    :persistent-hint="!!info"
    :hint="info"
    :rules="required ? isRequired : []"
    @change="$emit('change', {val: $event, name: name})"
  />
</template>
<script>
  import defaultProps from './props.js'

  export default {
    props: [...defaultProps.default],
    data () {
      return {
        valueCheck: true,
        isRequired: [
          (value) => {
            if (value === true) return true
            return `${this.label || this.name} ist erforderlich.`
          },
        ],
      }
    },
  }
</script>
